/* eslint-disable */
export const logoSVG = `
<svg id="logoSVG" class="logoSVG" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0" y="0"
     viewBox="0 0 522.59 198.62">
<g>
    <path d="M501.52,44.12h-4.42v-1.57h10.7v1.57h-4.42v13.03h-1.86V44.12z" class="logoLetter"/>
    <path d="M510.41,42.55h2.24l2.8,7.81c0.35,1,0.69,2.03,1.04,3.04h0.09c0.35-1.01,0.66-2.05,1.01-3.04l2.77-7.81h2.24
        v14.61h-1.73v-8.04c0-1.27,0.14-3.03,0.24-4.32h-0.09l-1.16,3.33l-2.75,7.56h-1.23l-2.77-7.56l-1.16-3.33h-0.09
        c0.09,1.29,0.24,3.05,0.24,4.32v8.04h-1.68V42.55z" class="logoLetter"/>
</g>
<g>
    <path class="logoLetter" d="M204.78,69.28l-7.18,14.35c0,0-7.43-3.96-14.11-3.96c-4.83,0-6.81,0.87-6.81,3.84c0,3.34,4.46,4.33,9.9,6.06
        c8.42,2.6,19.3,7.3,19.3,20.29c0,18.69-17.08,21.65-30.07,21.65c-15.34,0-24.5-8.54-24.5-8.54l8.91-14.97c0,0,8.66,7.3,15.72,7.3
        c3.09,0,6.81-0.37,6.81-4.58c0-4.58-6.93-4.95-14.11-8.66c-6.43-3.34-12.87-7.79-12.87-17.32c0-12.99,11.01-20.42,27.72-20.42
        C195.75,64.33,204.78,69.28,204.78,69.28z"/>
    <path class="logoLetter" d="M290.16,66.18l-49.87,95.65h-25.24l21.9-42.07l-30.07-53.58h25.24l17.7,32.54l15.1-32.54H290.16z"/>
    <path class="logoLetter" d="M310.2,136.47c2.35,3.84,7.67,8.17,17.2,8.17c6.06,0,15.47-3.59,15.47-14.85v-4.7
        c-4.7,5.2-12.13,6.44-20.79,6.44c-18.56,0-31.55-14.97-31.55-33.53s12.99-33.66,31.55-33.66c8.42,0,15.22,2.72,20.42,7.55h0.37
        v-5.69h21.9v65.21c0,21.29-15.72,32.3-37.37,32.3c-13.24,0-28.46-2.97-35.14-18.44L310.2,136.47z M328.63,112.83
        c8.54,0,14.73-6.19,14.73-14.85c0-8.79-6.56-14.97-14.73-14.97c-8.29,0-14.85,6.19-14.85,14.97
        C313.78,106.65,319.72,112.83,328.63,112.83z"/>
    <path class="logoLetter" d="M397.3,100.95c0,9.78,8.17,13.12,13.74,13.12c7.05,0,10.15-1.36,15.47-7.05l15.84,7.92
        c-7.18,12-18.81,16.58-32.92,16.58c-19.18,0-34.89-15.1-34.89-33.53c0-18.56,15.72-33.66,34.89-33.66
        c19.31,0,34.03,11.38,34.03,33.66c0,0.87,0,2.23-0.12,2.97H397.3z M422.05,88.83c-0.99-6.68-5.57-10.27-12-10.27
        c-7.55,0-11.76,4.46-12.13,10.27H422.05z"/>
    <path class="logoLetter" d="M22.77,100.95c0,9.78,8.17,13.12,13.74,13.12c7.05,0,10.15-1.36,15.47-7.05l15.84,7.92
        c-7.18,12-18.81,16.58-32.92,16.58C15.72,131.52,0,116.42,0,97.99c0-18.56,15.72-33.66,34.89-33.66
        c19.31,0,34.03,11.38,34.03,33.66c0,0.87,0,2.23-0.12,2.97H22.77z M47.52,88.83c-0.99-6.68-5.57-10.27-12-10.27
        c-7.55,0-11.76,4.46-12.13,10.27H47.52z"/>
    <path class="logoLetter" d="M456.94,42.55h21.9v23.64h13.36v16.71h-13.36v20.17c0,5.94,1.48,8.79,4.95,8.79c2.85,0,5.45-1.98,5.45-1.98
        l5.44,17.69c0,0-7.05,3.96-16.09,3.96c-17.45,0-21.65-12.99-21.65-24.25V82.89h-9.16V66.18h9.16V42.55z"/>
</g>
<g>
    <path class="logoLetter"  d="M157.07,176.67h12.05v1.7h-10.05v7.6h8.45v1.7h-8.45v8.84h10.38v1.7h-12.38V176.67z"/>
    <path class="logoLetter"  d="M189.26,187.08l-5.96-10.41h2.19l3.31,6.02c0.56,0.98,1.02,1.8,1.72,3.02h0.13c0.61-1.22,1.02-2.04,1.57-3.02
        l3.27-6.02h2.08l-5.96,10.51l6.39,11.05h-2.19l-3.52-6.29c-0.58-1.06-1.19-2.13-1.92-3.44h-0.13c-0.67,1.31-1.23,2.37-1.79,3.44
        l-3.5,6.29h-2.08L189.26,187.08z"/>
    <path class="logoLetter"  d="M210.79,176.67h5.72c4.86,0,7.92,1.6,7.92,6.14c0,4.35-3.02,6.43-7.79,6.43h-3.85v8.98h-2V176.67z
         M216.28,187.59c4.15,0,6.13-1.42,6.13-4.78c0-3.41-2.08-4.49-6.27-4.49h-3.36v9.27H216.28z"/>
    <path class="logoLetter"  d="M237.7,176.67h6.4c4.33,0,7.27,1.57,7.27,5.84c0,4.08-2.94,6.13-7.27,6.13h-4.4v9.59h-2V176.67z
         M243.71,186.99c3.63,0,5.64-1.45,5.64-4.48c0-3.08-2.01-4.19-5.64-4.19h-4.01v8.67H243.71z M243.68,188.08l1.72-0.83l6.47,10.98
        h-2.29L243.68,188.08z"/>
    <path class="logoLetter" d="M265.78,176.67h12.05v1.7h-10.05v7.6h8.45v1.7h-8.45v8.84h10.38v1.7h-12.38V176.67z"/>
    <path class="logoLetter" d="M291.91,195.39l1.24-1.37c1.56,1.71,3.77,2.82,6.18,2.82c3.11,0,5.02-1.62,5.02-4c0-2.5-1.68-3.29-3.77-4.22
        l-3.2-1.43c-1.93-0.84-4.37-2.25-4.37-5.44c0-3.2,2.76-5.47,6.47-5.47c2.58,0,4.75,1.15,6.14,2.62l-1.1,1.31
        c-1.29-1.3-2.95-2.14-5.04-2.14c-2.67,0-4.46,1.4-4.46,3.57c0,2.34,2.04,3.22,3.67,3.92l3.19,1.4c2.45,1.07,4.5,2.47,4.5,5.73
        c0,3.35-2.75,5.93-7.08,5.93C296.2,198.62,293.69,197.33,291.91,195.39z"/>
    <path class="logoLetter"  d="M319.09,195.39l1.24-1.37c1.56,1.71,3.77,2.82,6.17,2.82c3.11,0,5.02-1.62,5.02-4c0-2.5-1.68-3.29-3.77-4.22
        l-3.2-1.43c-1.93-0.84-4.37-2.25-4.37-5.44c0-3.2,2.76-5.47,6.47-5.47c2.58,0,4.75,1.15,6.14,2.62l-1.1,1.31
        c-1.29-1.3-2.95-2.14-5.04-2.14c-2.67,0-4.46,1.4-4.46,3.57c0,2.34,2.04,3.22,3.67,3.92l3.19,1.4c2.45,1.07,4.5,2.47,4.5,5.73
        c0,3.35-2.76,5.93-7.08,5.93C323.37,198.62,320.86,197.33,319.09,195.39z"/>
</g>
<path style="fill:#FFB200" d="M478.84,36.07H385.8V0h73.78c10.64,0,19.26,8.62,19.26,19.26V36.07z"/>
<path class="logoLetter"  d="M109.18,131.52c-11.68,0-21.2-5.19-28.57-15.58c-2.19-3.09-3.7-6.65-4.34-10.39
    c-2.63-15.21,2.33-27.26,14.88-36.18c3.11-2.21,6.7-3.69,10.45-4.35c12.5-2.2,22.94,0.85,31.34,9.15
    c6.56,6.56,9.84,14.48,9.84,25.41l0,31.94C142.78,131.52,113.18,131.52,109.18,131.52z M109.18,82.33c-2.14,0-4.14,0.41-6,1.22
    c-1.85,0.81-3.46,1.9-4.82,3.28c-1.36,1.38-2.44,2.99-3.24,4.84c-0.8,1.85-1.19,3.82-1.19,5.91c0,2.09,0.4,4.06,1.19,5.91
    c0.8,1.85,1.87,3.47,3.24,4.84c1.36,1.38,2.97,2.47,4.82,3.28c1.85,0.81,3.85,1.22,6,1.22c2.11,0,4.1-0.41,5.95-1.22
    c1.85-0.81,3.47-1.91,4.84-3.3c1.38-1.39,2.46-3,3.26-4.84c0.8-1.84,1.19-3.8,1.19-5.89c0-2.09-0.4-4.05-1.19-5.89
    c-0.8-1.84-1.88-3.45-3.26-4.84c-1.38-1.39-2.99-2.49-4.84-3.3C113.28,82.74,111.3,82.33,109.18,82.33z"/>
</svg>
`

export const logoIconSVG = `<svg class="logoIconSVG" width="32px" height="32px" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 512 512">
    <path fill="#fff" class="eLetterPath" d="M210.39,329.28c0,37.97,31.72,50.95,53.35,50.95c27.4,0,39.42-5.28,60.08-27.39l61.52,30.76
        c-27.88,46.62-73.05,64.4-127.85,64.4c-74.49,0-135.53-58.64-135.53-130.25c0-72.09,61.04-130.73,135.53-130.73
        c74.98,0,132.17,44.22,132.17,130.73c0,3.37,0,8.65-0.48,11.53H210.39z M306.52,282.18c-3.85-25.95-21.63-39.89-46.62-39.89
        c-29.32,0-45.66,17.3-47.11,39.89H306.52z"/>
    <path id="REC" fill="#E8B130" d="M121.84,64.42v103.88h267.94v-13.95c0-38-29.05-89.94-90.06-89.94
        C209.67,64.42,121.84,64.42,121.84,64.42z"/>
</svg>
`

export const iconHint = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
    <path d="M49.5,11c21.5,0,39,17.5,39,39S71,89,49.5,89s-39-17.5-39-39S28,11,49.5,11 M49.5,3c-26,0-47,21-47,47
    s21,47,47,47s47-21,47-47S75.5,3,49.5,3L49.5,3z"/>
    <path d="M44.5,60.9c-0.2-0.6-0.4-1.3-0.5-2.1s-0.2-1.5-0.2-2.2c0-1.1,0.1-2.1,0.3-3s0.5-1.7,0.9-2.5s0.9-1.5,1.6-2.3
    s1.4-1.4,2.2-2.1c0.9-0.8,1.7-1.4,2.3-2.1s1.2-1.3,1.7-1.9s0.8-1.3,1.1-2s0.4-1.5,0.4-2.3c0-0.7-0.1-1.4-0.4-2s-0.6-1.2-1.1-1.6
    s-1.1-0.8-1.8-1.1s-1.5-0.4-2.3-0.4c-1.8,0-3.7,0.4-5.7,1.1s-3.8,1.9-5.6,3.5V27.5c1.8-1.1,3.8-1.9,5.9-2.4s4.3-0.8,6.6-0.8
    c2.2,0,4.2,0.2,6.1,0.7s3.5,1.2,4.9,2.3s2.5,2.3,3.3,4s1.2,3.5,1.2,5.8c0,1.4-0.2,2.7-0.5,3.9s-0.8,2.3-1.4,3.3s-1.4,2-2.4,3
    s-2,1.9-3.2,2.9c-0.8,0.7-1.5,1.3-2.2,1.9s-1.1,1.2-1.5,1.7s-0.7,1.2-0.9,1.8s-0.3,1.4-0.3,2.2c0,0.6,0.1,1.1,0.2,1.7
    s0.4,1.1,0.6,1.5H44.5z M49.6,76.3c-1.9,0-3.4-0.6-4.7-1.7c-1.2-1.2-1.9-2.6-1.9-4.1c0-1.6,0.6-3,1.9-4.1s2.8-1.7,4.7-1.7
    c1.9,0,3.4,0.6,4.6,1.7c1.2,1.1,1.8,2.4,1.8,4.1c0,1.7-0.6,3.1-1.8,4.2C53.1,75.8,51.5,76.3,49.6,76.3z"/>
</svg>
`

export const leftArrowSVG = `
<svg class="leftArrowSvg" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17 20L11 14L17 8" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`

export const calcSVG = `<svg x="0" y="0" viewBox="0 0 58 58" width="24px" height="24px" fill="#fff">
    <path d="M21,14h-5V9c0-0.553-0.448-1-1-1s-1,0.447-1,1v5H9c-0.552,0-1,0.447-1,1s0.448,1,1,1h5v5c0,0.553,0.448,1,1,1s1-0.447,1-1
        v-5h5c0.552,0,1-0.447,1-1S21.552,14,21,14z"/>
    <path d="M49,14H37c-0.552,0-1,0.447-1,1s0.448,1,1,1h12c0.552,0,1-0.447,1-1S49.552,14,49,14z"/>
    <path d="M30,0h-2H0v28v2v28h28h2h28V30v-2V0H30z M2,2h26v26H2V2z M2,56V30h26v26H2z M56,56H30V30h26V56z M30,28V2h26v26H30z"/>
    <path d="M37,47h12c0.552,0,1-0.447,1-1s-0.448-1-1-1H37c-0.552,0-1,0.447-1,1S36.448,47,37,47z"/>
    <path d="M37,41h12c0.552,0,1-0.447,1-1s-0.448-1-1-1H37c-0.552,0-1,0.447-1,1S36.448,41,37,41z"/>
    <path d="M20.707,37.293c-0.391-0.391-1.023-0.391-1.414,0L15,41.586l-4.293-4.293c-0.391-0.391-1.023-0.391-1.414,0
        s-0.391,1.023,0,1.414L13.586,43l-4.293,4.293c-0.391,0.391-0.391,1.023,0,1.414C9.488,48.902,9.744,49,10,49
        s0.512-0.098,0.707-0.293L15,44.414l4.293,4.293C19.488,48.902,19.744,49,20,49s0.512-0.098,0.707-0.293
        c0.391-0.391,0.391-1.023,0-1.414L16.414,43l4.293-4.293C21.098,38.316,21.098,37.684,20.707,37.293z"/>
</svg>
`

export const eyeOpen = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="511.626px" height="511.626px" viewBox="0 0 511.626 511.626" style="enable-background:new 0 0 511.626 511.626;"
	 xml:space="preserve">
<g>
	<path d="M505.918,236.117c-26.651-43.587-62.485-78.609-107.497-105.065c-45.015-26.457-92.549-39.687-142.608-39.687
		c-50.059,0-97.595,13.225-142.61,39.687C68.187,157.508,32.355,192.53,5.708,236.117C1.903,242.778,0,249.345,0,255.818
		c0,6.473,1.903,13.04,5.708,19.699c26.647,43.589,62.479,78.614,107.495,105.064c45.015,26.46,92.551,39.68,142.61,39.68
		c50.06,0,97.594-13.176,142.608-39.536c45.012-26.361,80.852-61.432,107.497-105.208c3.806-6.659,5.708-13.223,5.708-19.699
		C511.626,249.345,509.724,242.778,505.918,236.117z M194.568,158.03c17.034-17.034,37.447-25.554,61.242-25.554
		c3.805,0,7.043,1.336,9.709,3.999c2.662,2.664,4,5.901,4,9.707c0,3.809-1.338,7.044-3.994,9.704
		c-2.662,2.667-5.902,3.999-9.708,3.999c-16.368,0-30.362,5.808-41.971,17.416c-11.613,11.615-17.416,25.603-17.416,41.971
		c0,3.811-1.336,7.044-3.999,9.71c-2.667,2.668-5.901,3.999-9.707,3.999c-3.809,0-7.044-1.334-9.71-3.999
		c-2.667-2.666-3.999-5.903-3.999-9.71C169.015,195.482,177.535,175.065,194.568,158.03z M379.867,349.04
		c-38.164,23.12-79.514,34.687-124.054,34.687c-44.539,0-85.889-11.56-124.051-34.687s-69.901-54.2-95.215-93.222
		c28.931-44.921,65.19-78.518,108.777-100.783c-11.61,19.792-17.417,41.207-17.417,64.236c0,35.216,12.517,65.329,37.544,90.362
		s55.151,37.544,90.362,37.544c35.214,0,65.329-12.518,90.362-37.544s37.545-55.146,37.545-90.362
		c0-23.029-5.808-44.447-17.419-64.236c43.585,22.265,79.846,55.865,108.776,100.783C449.767,294.84,418.031,325.913,379.867,349.04
		z"/>
</g>
</svg>
`

export const eyeClosed = `
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="511.626px" height="511.627px" viewBox="0 0 511.626 511.627" style="enable-background:new 0 0 511.626 511.627;"
	 xml:space="preserve">
	<g>
		<path d="M361.161,291.652c15.037-21.796,22.56-45.922,22.56-72.375c0-7.422-0.76-15.417-2.286-23.984l-79.938,143.321
			C326.235,329.101,346.125,313.438,361.161,291.652z"/>
		<path d="M372.872,94.221c0.191-0.378,0.28-1.235,0.28-2.568c0-3.237-1.522-5.802-4.571-7.715c-0.568-0.38-2.423-1.475-5.568-3.287
			c-3.138-1.805-6.14-3.567-8.989-5.282c-2.854-1.713-5.989-3.472-9.422-5.28c-3.426-1.809-6.375-3.284-8.846-4.427
			c-2.479-1.141-4.189-1.713-5.141-1.713c-3.426,0-6.092,1.525-7.994,4.569l-15.413,27.696c-17.316-3.234-34.451-4.854-51.391-4.854
			c-51.201,0-98.404,12.946-141.613,38.831C70.998,156.08,34.836,191.385,5.711,236.114C1.903,242.019,0,248.586,0,255.819
			c0,7.231,1.903,13.801,5.711,19.698c16.748,26.073,36.592,49.396,59.528,69.949c22.936,20.561,48.011,37.018,75.229,49.396
			c-8.375,14.273-12.562,22.556-12.562,24.842c0,3.425,1.524,6.088,4.57,7.99c23.219,13.329,35.97,19.985,38.256,19.985
			c3.422,0,6.089-1.529,7.992-4.575l13.99-25.406c20.177-35.967,50.248-89.931,90.222-161.878
			C322.908,183.871,352.886,130.005,372.872,94.221z M158.456,362.885C108.97,340.616,68.33,304.93,36.547,255.822
			c28.931-44.921,65.19-78.518,108.777-100.783c-11.61,19.792-17.417,41.206-17.417,64.237c0,20.365,4.661,39.68,13.99,57.955
			c9.327,18.274,22.27,33.4,38.83,45.392L158.456,362.885z M265.525,155.887c-2.662,2.667-5.906,3.999-9.712,3.999
			c-16.368,0-30.361,5.808-41.971,17.416c-11.613,11.615-17.416,25.603-17.416,41.971c0,3.811-1.336,7.044-3.999,9.71
			c-2.668,2.667-5.902,3.999-9.707,3.999c-3.809,0-7.045-1.334-9.71-3.999c-2.667-2.666-3.999-5.903-3.999-9.71
			c0-23.79,8.52-44.206,25.553-61.242c17.034-17.034,37.447-25.553,61.241-25.553c3.806,0,7.043,1.336,9.713,3.999
			c2.662,2.664,3.996,5.901,3.996,9.707C269.515,149.992,268.181,153.228,265.525,155.887z"/>
		<path d="M505.916,236.114c-10.853-18.08-24.603-35.594-41.255-52.534c-16.646-16.939-34.022-31.496-52.105-43.68l-17.987,31.977
			c31.785,21.888,58.625,49.87,80.51,83.939c-23.024,35.782-51.723,65-86.07,87.648c-34.358,22.661-71.712,35.693-112.065,39.115
			l-21.129,37.688c42.257,0,82.18-9.038,119.769-27.121c37.59-18.076,70.668-43.488,99.216-76.225
			c13.322-15.421,23.695-29.219,31.121-41.401c3.806-6.476,5.708-13.046,5.708-19.702
			C511.626,249.157,509.724,242.59,505.916,236.114z"/>
	</g>
</svg>
`

export const svgIconTelegram = `
<svg viewBox="0 0 18 18" width="18" height="18" fill="#1B9CC6" stroke="none">
<g id="svgIconTelegram">
    <path class="st0" d="M8.8,17.5C4,17.6,0,13.6,0,8.7C0,4,4-0.3,9.3,0c4.4,0.3,8,3.8,8.2,8.3C17.7,13.5,13.5,17.6,8.8,17.5z M13,5.4
        c0-0.5-0.2-0.8-0.7-0.7c-0.2,0-0.4,0.1-0.6,0.1C9.7,5.5,7.7,6.2,5.7,7C5.2,7.1,4.7,7.3,4.3,7.5C3.7,7.7,3.6,8.3,4,8.7
        C4.2,8.8,4.4,9,4.6,9.1c0.7,0.4,1.5,0.7,2.2,1.1c0.3,0.2,0.6,0.5,0.7,0.8c0.4,0.8,0.8,1.7,1.2,2.5C8.9,13.7,9.2,14,9.6,14
        c0.4,0,0.6-0.4,0.7-0.7c0.9-2.5,1.7-4.9,2.6-7.4C13,5.7,13,5.5,13,5.4z"/>
</g>
</svg>
`

export const svgIconViber = `
<svg fill="#834995" x="0px" y="0px" viewBox="0 0 20 21.6" width="20" height="21.6">
    <g>
    <path  d="M17.8,12c-0.5,4.1-3.4,4.3-3.9,4.5c-0.2,0.1-2.3,0.6-5,0.4L6,20.1l0-3.7l0,0C2.1,15.3,2.2,11.2,2.3,9
        c0-2.2,0.4-4,1.6-5.1c2.1-2,6.5-1.7,6.5-1.7c3.7,0,5.5,1.2,5.9,1.5C17.7,4.9,18.4,7.8,17.8,12 M17.3,2.7c-0.5-0.5-2.4-1.9-6.8-1.9
        c0,0-5.2-0.3-7.7,2C1.4,4.3,0.9,6.4,0.9,9c-0.1,2.6-0.1,7.5,4.5,8.8l0,0l0,2c0,0,0,0.8,0.5,1c0.6,0.2,1-0.4,1.6-1.1
        c0.3-0.4,0.8-0.9,1.1-1.3c3.1,0.3,5.6-0.3,5.8-0.4c0.6-0.2,4.2-0.7,4.8-5.6C19.8,7.3,18.9,4.2,17.3,2.7"/>
    <path d="M14.5,12.6c0,0-0.6-0.5-0.9-0.7c-0.3-0.2-0.7-0.5-0.9-0.6c-0.4-0.2-0.9-0.1-1.1,0.2l-0.4,0.5
        c-0.2,0.2-0.5,0.2-0.6,0.2C10.1,12,9.5,11.6,8.9,11c-0.6-0.6-1-1.2-1.1-1.9l0,0c0-0.3,0-0.4,0.1-0.6l0,0C8.2,8.3,8.7,8,8.7,7.7
        C8.9,7.2,8.1,6.2,8,6.1c0,0-0.6-0.8-0.8-0.9C7,5,6.6,4.9,6.3,5.1l0,0C5.2,5.8,4.9,6.2,5,6.7L5,7c0.5,1.6,1.5,3.3,3,4.9
        c1.5,1.5,3,2.4,4.6,3.1c0.4,0.1,0.8,0,1.3-0.3l0,0c0.3-0.3,0.6-0.6,0.8-1l0,0C14.8,13.2,14.8,12.8,14.5,12.6"/>
    <path d="M12.1,9.1C12,9.1,12,9,12,8.9c0-0.4-0.1-0.7-0.3-1c-0.2-0.2-0.5-0.3-0.9-0.4c-0.1,0-0.1-0.1-0.1-0.1
        c0-0.1,0.1-0.1,0.1-0.1c0.5,0,0.8,0.2,1.1,0.4c0.2,0.3,0.4,0.6,0.4,1.1C12.2,9,12.1,9.1,12.1,9.1L12.1,9.1"/>
    <path d="M13.2,9.5L13.2,9.5c-0.1,0-0.1-0.1-0.1-0.1c0-0.8-0.2-1.5-0.7-2c-0.2-0.3-0.5-0.5-0.9-0.6
        c-0.3-0.2-0.7-0.2-1.2-0.3c-0.1,0-0.1-0.1-0.1-0.1c0-0.1,0.1-0.1,0.1-0.1c0.9,0.1,1.7,0.4,2.2,1c0.5,0.6,0.8,1.3,0.8,2.2
        C13.3,9.4,13.3,9.5,13.2,9.5"/>
    <path d="M14.4,9.9c-0.1,0-0.1-0.1-0.1-0.1c0-0.7-0.1-1.4-0.3-1.9c-0.2-0.6-0.5-1-0.9-1.5c-0.4-0.4-0.9-0.7-1.4-0.9
        c-0.5-0.2-1.1-0.3-1.7-0.3c-0.1,0-0.1-0.1-0.1-0.1S9.9,5,10,5h0c1.3,0,2.4,0.4,3.2,1.3c0.4,0.4,0.8,0.9,1,1.5
        c0.2,0.6,0.3,1.3,0.3,2C14.5,9.9,14.5,9.9,14.4,9.9L14.4,9.9z"/>
    </g>
</svg>
`

export const svgShopping = `<svg width="38" height="46" viewBox="0 0 38 46" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.89066 7.35751L10.7152 4.9396C10.8159 4.6666 10.9914 4.42896 11.2208 4.25504C11.4502 4.08111 11.7237 3.97824 12.0088 3.95871H21.8983C22.0542 3.94561 22.2092 3.99227 22.3333 4.0896C22.4573 4.18692 22.5414 4.32793 22.5691 4.48512H13.0094C12.7243 4.50426 12.4505 4.60697 12.2211 4.78095C11.9917 4.95493 11.8162 5.19279 11.7158 5.46602L10.8913 7.88392C10.8382 8.02893 10.8228 8.1855 10.8465 8.3384H10.5167C9.98672 8.3384 9.70654 7.8921 9.89066 7.35751Z" fill="url(#paint0_linear5125)"/>
<path d="M32.0888 0.352051C32.0888 0.352051 33.637 0.950397 33.6898 1.98688C33.7426 3.02336 35.5582 18.4986 36.3042 25.2553C37.0503 32.0121 37.9053 41.4401 37.212 42.094C36.5188 42.748 28.4753 45.9817 28.1983 45.9996C27.9213 46.0176 29.5271 1.76781 32.0888 0.352051Z" fill="url(#paint1_linear54512)"/>
<path d="M28.1985 45.9999C28.1985 45.9999 29.9597 40.1325 30.0077 39.969C30.0557 39.8055 30.7554 10.8691 30.7554 10.8691C30.7554 10.8691 26.3942 22.1135 28.1985 45.9999Z" fill="url(#paint2_linear4512)"/>
<path d="M30.0075 39.9692L37.212 42.0945L28.1983 46.0001L30.0075 39.9692Z" fill="url(#paint3_linear52421)"/>
<path d="M1.4644 26.4484C2.42501 14.9507 5.79835 0.194762 5.79835 0.194762C5.79835 0.194762 31.1472 -0.0520966 31.9301 0.0100268C32.713 0.0721502 31.5091 4.98971 30.185 15.1354C28.861 25.2812 28.5584 41.327 28.5584 42.249C28.5584 43.1711 28.6192 45.9993 28.1982 45.9993C27.7771 45.9993 0.80158 42.679 0.80158 42.679C0.319675 41.4496 0.500591 37.9445 1.4644 26.4484ZM10.5165 8.33783H20.406C20.6915 8.319 20.9655 8.21642 21.1953 8.04242C21.425 7.86842 21.6007 7.6304 21.7012 7.35693L22.5242 4.93903C22.7099 4.3979 22.4281 3.95813 21.8982 3.95813H12.0087C11.7236 3.97766 11.4501 4.08053 11.2207 4.25446C10.9913 4.42839 10.8158 4.66603 10.7151 4.93903L9.89055 7.35693C9.70643 7.89152 9.98661 8.33783 10.5165 8.33783Z" fill="url(#paint4_linear12187)"/>
<defs>
<linearGradient id="paint0_linear5125" x1="22.9485" y1="6.1412" x2="10.2509" y2="6.1412" gradientUnits="userSpaceOnUse">
<stop stop-color="#8A6A26"/>
<stop offset="1" stop-color="#544118"/>
</linearGradient>
<linearGradient id="paint1_linear54512" x1="39.8265" y1="28.7571" x2="29.2894" y2="22.9103" gradientUnits="userSpaceOnUse">
<stop stop-color="#7D6022"/>
<stop offset="1" stop-color="#544118"/>
</linearGradient>
<linearGradient id="paint2_linear4512" x1="-186.446" y1="6120.81" x2="-242.319" y2="6120.81" gradientUnits="userSpaceOnUse">
<stop stop-color="#8A6A26"/>
<stop offset="1" stop-color="#544118"/>
</linearGradient>
<linearGradient id="paint3_linear52421" x1="-544.263" y1="1683.74" x2="-650.975" y2="1443.02" gradientUnits="userSpaceOnUse">
<stop stop-color="#8A6A26"/>
<stop offset="1" stop-color="#544118"/>
</linearGradient>
<linearGradient id="paint4_linear12187" x1="13.8739" y1="45.7574" x2="19.0712" y2="0.0649903" gradientUnits="userSpaceOnUse">
<stop stop-color="#6E5727"/>
<stop offset="0.08" stop-color="#A68847"/>
<stop offset="0.72" stop-color="#CFAD67"/>
<stop offset="1" stop-color="#E0C489"/>
</linearGradient>
</defs>
</svg>`

export const svgParcel = `<svg width="50" height="46" viewBox="0 0 50 46" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M50.0005 12.2676V33.7343L25.0023 45.9985L0.00402832 33.7343V12.2676L25.0023 0L50.0005 12.2676Z" fill="url(#paint0_linear51744)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M25.0023 0V45.9985L0.00402832 33.7343V12.2676L25.0023 0Z" fill="url(#paint1_linear)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M50.0001 33.7323L25.0018 45.9999V24.5333L50.0001 12.2656V33.7323Z" fill="url(#paint2_linear51548)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.58307e-05 33.7323L25.0018 45.9999V24.5333L8.58307e-05 12.2656V33.7323Z" fill="url(#paint3_linear874114)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.4868 15.3279L0.00337219 12.2662L25.0016 0.00195312L37.4885 3.06029L12.4868 15.3279Z" fill="url(#paint4_linear571524)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M49.9859 12.2726L39.2179 3.90674L14.2162 16.1744L24.9876 24.5402L49.9859 12.2726Z" fill="url(#paint5_linear)"/>
<defs>
<linearGradient id="paint0_linear51744" x1="49.4742" y1="45.464" x2="53.2094" y2="5.48872" gradientUnits="userSpaceOnUse">
<stop stop-color="#C6803A"/>
<stop offset="1" stop-color="#A4601D"/>
</linearGradient>
<linearGradient id="paint1_linear" x1="28.6577" y1="31.137" x2="8.35702" y2="9.30115" gradientUnits="userSpaceOnUse">
<stop stop-color="#9B5F1F"/>
<stop offset="1" stop-color="#824C1B"/>
</linearGradient>
<linearGradient id="paint2_linear51548" x1="46.74" y1="38.0395" x2="43.9071" y2="17.5172" gradientUnits="userSpaceOnUse">
<stop stop-color="#9B5F1F"/>
<stop offset="1" stop-color="#824C1B"/>
</linearGradient>
<linearGradient id="paint3_linear874114" x1="3.07079" y1="37.7664" x2="6.31329" y2="17.4132" gradientUnits="userSpaceOnUse">
<stop stop-color="#C6803A"/>
<stop offset="1" stop-color="#A4601D"/>
</linearGradient>
<linearGradient id="paint4_linear571524" x1="-2.18408" y1="15.4338" x2="-6.28012" y2="-6.6864" gradientUnits="userSpaceOnUse">
<stop stop-color="#EEC089"/>
<stop offset="1" stop-color="#C18B54"/>
</linearGradient>
<linearGradient id="paint5_linear" x1="-60.6451" y1="-4.1224" x2="-31.5018" y2="57.9109" gradientUnits="userSpaceOnUse">
<stop stop-color="#EEC089"/>
<stop offset="1" stop-color="#C18B54"/>
</linearGradient>
</defs>
</svg>`


export const plusSVG = `<svg class="ico" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.5 8.99998H8.99998V15.5H7.00002V8.99998H0.5V7.00002H7.00002V0.5H8.99998V7.00002H15.5V8.99998Z" fill="white"/>
</svg>`


export const resultBoxSVG = `<svg class="boxSvg" width="375" height="353" viewBox="0 0 375 353" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask4852" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="375" height="353">
<rect width="375" height="353" fill="#C4C4C4"/>
</mask>
<g mask="url(#mask4852)">
<path opacity="0.05" fill-rule="evenodd" clip-rule="evenodd" d="M-132 235.655L101 353L334 235.655L101 118.277L-132 235.655Z" fill="#363636"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M132.727 225.698V240.123L161.071 254.31L189.414 240.123V225.698L161.071 211.511L132.727 225.698Z" fill="#9B5F1F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M161.071 211.511V254.31L189.414 240.123V225.698L161.071 211.511Z" fill="#8D551E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M132.727 240.125L161.07 254.312V239.883L132.727 225.696V240.125Z" fill="url(#paint5950_linear)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M189.417 240.125L161.07 254.312V239.883L189.417 225.696V240.125Z" fill="url(#paint1745_linear)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M189.414 225.697L161.071 239.883L132.724 225.697L161.071 211.511L189.414 225.697Z" fill="url(#paint7451_linear)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M64.7351 259.897V274.322L93.0782 288.509L121.421 274.322V259.897L93.0782 245.71L64.7351 259.897Z" fill="#9B5F1F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M93.0782 245.71V288.509L121.421 274.322V259.897L93.0782 245.71Z" fill="#8D551E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M64.7329 274.324L93.076 288.511V274.082L64.7329 259.895V274.324Z" fill="url(#paint37514_linear)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M121.425 274.324L93.078 288.511V274.082L121.425 259.895V274.324Z" fill="url(#paint455154_linear)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M121.421 259.897L93.0777 274.082L64.7306 259.897L93.0777 245.711L121.421 259.897Z" fill="url(#paint5478514_linear)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M150.987 135.819H171.503V225.679C171.503 225.679 169.375 229.682 161.23 229.647C153.764 229.613 150.987 225.679 150.987 225.679V135.819Z" fill="#242424"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M81.7384 181.891H102.255V260.336C102.255 260.336 100.126 264.339 91.9811 264.304C84.5151 264.27 81.7384 260.336 81.7384 260.336V181.891Z" fill="#242424"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M-0.113022 62.0027V170.497L123.755 232.481L247.624 170.497V62.0027L123.755 0.0012207L-0.113022 62.0027Z" fill="#9B5F1F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M123.755 0.0012207V232.481L247.624 170.497V62.0027L123.755 0.0012207Z" fill="#8D551E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M-0.114616 170.487L123.754 232.488V123.994L-0.114616 61.9927V170.487Z" fill="url(#paint65484_linear)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M247.639 170.487L123.754 232.488V123.994L247.639 61.9927V170.487Z" fill="url(#paint774541_linear)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M206.564 167.954C205.439 170.639 203.518 172.926 201.173 174.651C196.984 177.732 192.656 178.956 189.085 178.07C185.119 177.086 181.206 174.693 181.356 169.944C181.4 168.558 182.357 167.419 183.573 166.753C189.267 163.634 199.36 158.192 204.548 156.197C205.248 155.928 206.057 155.786 206.684 156.199C208.995 157.721 208.568 163.172 206.564 167.954Z" fill="#543312"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M187.686 172.073C193.645 170.651 197.85 173.881 199.347 175.217C199.888 176.301 198.373 176.87 195.289 177.902C194.955 178.014 194.608 178.091 194.258 178.127C189.514 178.624 187.556 178.35 183.952 175.787C182.413 174.693 184.591 173.929 187.686 172.073Z" fill="#D34214"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M247.63 61.9963L123.762 123.993L-0.123932 61.9963L123.762 0L247.63 61.9963Z" fill="url(#paint84514_linear)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M201.177 85.2461L77.3082 23.2498L61.8139 30.9991L185.699 92.9954L201.177 85.2461Z" fill="black" fill-opacity="0.2"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M201.177 115.743V85.2461L185.699 92.9954L185.751 123.492L201.177 115.743Z" fill="black" fill-opacity="0.2"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M222.248 136.946C217.564 136.655 214.211 129.278 214.758 120.469C215.306 111.661 219.547 104.756 224.23 105.047C228.914 105.338 232.267 112.715 231.72 121.524C231.172 130.332 226.932 137.237 222.248 136.946Z" fill="#543312"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M154.302 167.868C149.264 167.868 145.179 160.187 145.179 150.711C145.179 141.235 149.264 133.553 154.302 133.553C159.341 133.553 163.425 141.235 163.425 150.711C163.425 160.187 159.341 167.868 154.302 167.868Z" fill="#543312"/>
</g>
<defs>
<linearGradient id="paint5950_linear" x1="119.147" y1="256.3" x2="120.01" y2="203.858" gradientUnits="userSpaceOnUse">
<stop stop-color="#9B5F1F"/>
<stop offset="1" stop-color="#824C1B"/>
</linearGradient>
<linearGradient id="paint1745_linear" x1="196.1" y1="258.21" x2="196.1" y2="216.973" gradientUnits="userSpaceOnUse">
<stop stop-color="#C6803A"/>
<stop offset="1" stop-color="#A4601D"/>
</linearGradient>
<linearGradient id="paint7451_linear" x1="134.874" y1="248.692" x2="112.042" y2="190.83" gradientUnits="userSpaceOnUse">
<stop stop-color="#EEC089"/>
<stop offset="1" stop-color="#C18B54"/>
</linearGradient>
<linearGradient id="paint37514_linear" x1="51.1528" y1="290.499" x2="52.0153" y2="238.057" gradientUnits="userSpaceOnUse">
<stop stop-color="#9B5F1F"/>
<stop offset="1" stop-color="#824C1B"/>
</linearGradient>
<linearGradient id="paint455154_linear" x1="128.108" y1="292.409" x2="128.108" y2="251.172" gradientUnits="userSpaceOnUse">
<stop stop-color="#C6803A"/>
<stop offset="1" stop-color="#A4601D"/>
</linearGradient>
<linearGradient id="paint5478514_linear" x1="66.881" y1="282.891" x2="44.049" y2="225.029" gradientUnits="userSpaceOnUse">
<stop stop-color="#EEC089"/>
<stop offset="1" stop-color="#C18B54"/>
</linearGradient>
<linearGradient id="paint65484_linear" x1="-29.2168" y1="195.061" x2="-22.214" y2="-34.0253" gradientUnits="userSpaceOnUse">
<stop stop-color="#9B5F1F"/>
<stop offset="1" stop-color="#824C1B"/>
</linearGradient>
<linearGradient id="paint774541_linear" x1="253.058" y1="204.087" x2="253.058" y2="23.8699" gradientUnits="userSpaceOnUse">
<stop stop-color="#C6803A"/>
<stop offset="1" stop-color="#A4601D"/>
</linearGradient>
<linearGradient id="paint84514_linear" x1="9.27402" y1="162.49" x2="-90.509" y2="-90.3851" gradientUnits="userSpaceOnUse">
<stop stop-color="#EEC089"/>
<stop offset="1" stop-color="#C18B54"/>
</linearGradient>
</defs>
</svg>`

export const resultErrorBoxSVG = `<svg class="boxSvg" width="375" height="353" viewBox="0 0 375 353" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask45188" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="375" height="353">
<rect width="375" height="353" fill="#C4C4C4"/>
</mask>
<g mask="url(#mask45188)">
<path opacity="0.05" fill-rule="evenodd" clip-rule="evenodd" d="M-132 235.655L101 353L334 235.655L101 118.277L-132 235.655Z" fill="#363636"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M132.727 225.698V240.123L161.071 254.31L189.414 240.123V225.698L161.071 211.511L132.727 225.698Z" fill="#9B5F1F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M161.071 211.511V254.31L189.414 240.123V225.698L161.071 211.511Z" fill="#8D551E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M132.727 240.125L161.07 254.312V239.883L132.727 225.696V240.125Z" fill="url(#paint8154_linear)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M189.417 240.125L161.07 254.312V239.883L189.417 225.696V240.125Z" fill="url(#paint158_linear)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M189.414 225.697L161.071 239.883L132.724 225.697L161.071 211.511L189.414 225.697Z" fill="url(#paint275146_linear)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M64.7351 259.897V274.322L93.0782 288.509L121.421 274.322V259.897L93.0782 245.71L64.7351 259.897Z" fill="#9B5F1F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M93.0782 245.71V288.509L121.421 274.322V259.897L93.0782 245.71Z" fill="#8D551E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M64.7329 274.324L93.076 288.511V274.082L64.7329 259.895V274.324Z" fill="url(#paint351549_linear)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M121.425 274.324L93.078 288.511V274.082L121.425 259.895V274.324Z" fill="url(#paint4647_linear)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M121.421 259.897L93.0777 274.082L64.7306 259.897L93.0777 245.711L121.421 259.897Z" fill="url(#paint48754_linear)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M150.987 135.819H171.503V225.679C171.503 225.679 169.375 229.682 161.23 229.647C153.764 229.613 150.987 225.679 150.987 225.679V135.819Z" fill="#242424"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M81.7384 181.891H102.255V260.336C102.255 260.336 100.126 264.339 91.9811 264.304C84.5151 264.27 81.7384 260.336 81.7384 260.336V181.891Z" fill="#242424"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M-0.113022 62.0027V170.497L123.755 232.481L247.624 170.497V62.0027L123.755 0.0012207L-0.113022 62.0027Z" fill="#9B5F1F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M123.755 0.0012207V232.481L247.624 170.497V62.0027L123.755 0.0012207Z" fill="#8D551E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M-0.114616 170.487L123.754 232.488V123.994L-0.114616 61.9927V170.487Z" fill="url(#paint84751_linear)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M247.639 170.487L123.754 232.488V123.994L247.639 61.9927V170.487Z" fill="url(#paint8624_linear)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M183.205 165.986C184.347 163.306 186.27 161.022 188.608 159.285C192.966 156.049 197.477 154.758 201.193 155.679C205.304 156.698 209.361 159.184 209.168 164.124C209.114 165.51 208.158 166.65 206.941 167.316C201.062 170.534 190.56 176.195 185.198 178.247C184.498 178.515 183.693 178.654 183.06 178.251C180.62 176.697 181.083 170.968 183.205 165.986Z" fill="#543312"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M195.354 161.699C191.249 166.507 185.78 166.372 183.72 166.169C182.617 165.599 183.755 164.646 185.701 161.995C185.941 161.667 186.216 161.364 186.524 161.1C190.201 157.939 191.421 156.829 195.308 155.718C197.181 155.183 196.808 158.27 195.354 161.699Z" fill="#D34214"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M247.63 61.9963L123.762 123.993L-0.123932 61.9963L123.762 0L247.63 61.9963Z" fill="url(#paint8654_linear)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M201.177 85.2461L77.3082 23.2498L61.8139 30.9991L185.699 92.9954L201.177 85.2461Z" fill="black" fill-opacity="0.2"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M201.177 115.743V85.2461L185.699 92.9954L185.751 123.492L201.177 115.743Z" fill="black" fill-opacity="0.2"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M222.248 136.946C217.564 136.655 214.211 129.278 214.758 120.469C215.306 111.661 219.547 104.756 224.23 105.047C228.914 105.338 232.267 112.715 231.72 121.524C231.172 130.332 226.932 137.237 222.248 136.946Z" fill="#543312"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M154.302 167.868C149.264 167.868 145.179 160.187 145.179 150.711C145.179 141.235 149.264 133.553 154.302 133.553C159.341 133.553 163.425 141.235 163.425 150.711C163.425 160.187 159.341 167.868 154.302 167.868Z" fill="#543312"/>
</g>
<defs>
<linearGradient id="paint8154_linear" x1="119.147" y1="256.3" x2="120.01" y2="203.858" gradientUnits="userSpaceOnUse">
<stop stop-color="#9B5F1F"/>
<stop offset="1" stop-color="#824C1B"/>
</linearGradient>
<linearGradient id="paint158_linear" x1="196.1" y1="258.21" x2="196.1" y2="216.973" gradientUnits="userSpaceOnUse">
<stop stop-color="#C6803A"/>
<stop offset="1" stop-color="#A4601D"/>
</linearGradient>
<linearGradient id="paint275146_linear" x1="134.874" y1="248.692" x2="112.042" y2="190.83" gradientUnits="userSpaceOnUse">
<stop stop-color="#EEC089"/>
<stop offset="1" stop-color="#C18B54"/>
</linearGradient>
<linearGradient id="paint351549_linear" x1="51.1528" y1="290.499" x2="52.0153" y2="238.057" gradientUnits="userSpaceOnUse">
<stop stop-color="#9B5F1F"/>
<stop offset="1" stop-color="#824C1B"/>
</linearGradient>
<linearGradient id="paint4647_linear" x1="128.108" y1="292.409" x2="128.108" y2="251.172" gradientUnits="userSpaceOnUse">
<stop stop-color="#C6803A"/>
<stop offset="1" stop-color="#A4601D"/>
</linearGradient>
<linearGradient id="paint48754_linear" x1="66.881" y1="282.891" x2="44.049" y2="225.029" gradientUnits="userSpaceOnUse">
<stop stop-color="#EEC089"/>
<stop offset="1" stop-color="#C18B54"/>
</linearGradient>
<linearGradient id="paint84751_linear" x1="-29.2168" y1="195.061" x2="-22.214" y2="-34.0253" gradientUnits="userSpaceOnUse">
<stop stop-color="#9B5F1F"/>
<stop offset="1" stop-color="#824C1B"/>
</linearGradient>
<linearGradient id="paint8624_linear" x1="253.058" y1="204.087" x2="253.058" y2="23.8699" gradientUnits="userSpaceOnUse">
<stop stop-color="#C6803A"/>
<stop offset="1" stop-color="#A4601D"/>
</linearGradient>
<linearGradient id="paint8654_linear" x1="9.27402" y1="162.49" x2="-90.509" y2="-90.3851" gradientUnits="userSpaceOnUse">
<stop stop-color="#EEC089"/>
<stop offset="1" stop-color="#C18B54"/>
</linearGradient>
</defs>
 </svg>`

export const leftArr = `<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7 13L1 7L7 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`

export const starIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.8999 2.79998L15.2999 6.99997C15.5999 7.49997 16.0999 7.79997 16.5999 7.99997L21.2999 8.99997C22.0999 9.19997 22.3999 10.1 21.7999 10.7L18.5999 14.3C18.1999 14.7 17.9999 15.3 18.0999 15.9L18.5999 20.7C18.6999 21.5 17.8999 22 17.1999 21.7L12.7999 19.7C12.2999 19.5 11.6999 19.5 11.1999 19.7L6.79995 21.7C6.09995 22 5.29995 21.4 5.39995 20.7L5.89995 15.9C5.99995 15.3 5.79995 14.8 5.39995 14.3L2.19995 10.7C1.69995 10.1 1.99995 9.19997 2.69995 8.99997L7.39995 7.99997C7.99995 7.89997 8.39995 7.49997 8.69995 6.99997L11.0999 2.79998C11.4999 2.19998 12.4999 2.19998 12.8999 2.79998Z" />
</svg>`